@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

body {
  background-color: #113142;
  height: 100%;
  margin: 0;
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  color: white;
}

#root {
  height: 100%;
}

.page {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.scene-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

text {
  fill: white;
}

.hoverable:hover {
  cursor: pointer;
}

button {
  background-color: #444444;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: 'Valera Round', 'Verdana', sans-serif;
  font-size: 18px;
  margin: 5px;
  padding: 10px;
  text-decoration: none;
}

button:hover {
  cursor: pointer;
}

#control-panel {
  background-color: #708090;
  border: 4px solid #5b6978;
  border-radius: 5px;
  color: black;
  margin-top: 10px;
  padding: 15px;
}

#control-panel-container {
  display: flex;
  align-items: flex-start;
}

#program {
  background-color: black;
  color: white;
  min-height: 250px;
  width: 250px;
  padding: 20px;
}

#blocks {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

button.block {
  background-color: black;
  border-radius: 3px;
  font-size: 14px;
}